import React, { useMemo } from 'react';

import { AssistanceRequest } from 'types/assistance-request';
import { RoomParticipant } from 'types/room-participant';
import { addRequestsCountToParticipants } from 'utils/add-requests-count-to-participants';

type Props = {
  assistanceRequests: AssistanceRequest[];
  roomParticipants: RoomParticipant[];
};

export const RoomParticipants: React.FC<Props> = ({ assistanceRequests, roomParticipants }) => {
  const participants = useMemo(
    () => addRequestsCountToParticipants(roomParticipants, assistanceRequests),
    [roomParticipants, assistanceRequests]
  );

  return (
    <>
      <h3 className="session-participants-title">Session Participants</h3>
      <ol>
        {participants?.map((p, i) => (
          <li key={`room-participant-${i}`}>
            {p?.name} ({p?.requestsCount || 0})
          </li>
        ))}
      </ol>
    </>
  );
};
