import React from 'react';

import { RoomParticipant } from 'types/room-participant';
import { AssistanceRequest } from 'types/assistance-request';

import { AssistanceRequestsItem } from './assistance-requests-item';

type Props = {
  assistanceRequests: AssistanceRequest[];
  roomParticipants: RoomParticipant[];
  showCommentsMessages: boolean;
  answerAssistanceRequest: (id: string) => void;
};

export const AssistanceRequestsList: React.FC<Props> = ({
  assistanceRequests,
  roomParticipants,
  showCommentsMessages,
  answerAssistanceRequest,
}) => (
  <ol className="assistance-requests-container">
    {assistanceRequests?.map((a, i) => (
      <AssistanceRequestsItem
        key={`assistance-request-${i}`}
        assistanceRequest={a}
        answerAssistanceRequest={answerAssistanceRequest}
        roomParticipants={roomParticipants}
        showCommentMessage={showCommentsMessages}
        ordinalPlace={i + 1}
      />
    ))}
  </ol>
);
