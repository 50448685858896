import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  clearSessionData,
  getClassCode,
  getToken,
  removeToken,
  saveClassCode,
  saveSessionId,
  saveToken,
} from 'utils/local-storage';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import useSocket from 'hooks/use-socket';
import { Footer, NavBar } from 'shared';
import { RoomListener } from 'types/room-listener';
import { useQueryParams } from 'hooks/use-query-params';
import { getTeacherPayloadByToken } from 'features/teacher/api';
import { userContext } from 'context/user-context';
import { WP_URL } from 'constants/config';
import TeachersClassroom from './components/teachers-classroom';
import { StartSession } from './components/start-session';
import './styles.scss';

const Classroom: FC = () => {
  const socket = useSocket();
  const query = useQueryParams();
  const { replace } = useHistory();
  const { update: updateUserContext, state: user } = useContext(userContext);
  const token = useMemo(() => query.get('token') || getToken(), []);
  const [roomCreated, setRoomCreated] = useState<boolean>(!!getClassCode());
  const [isTokenVerified, setIsTokenVerified] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [isLoginOut, setIsLoginOut] = useState<boolean>(false);
  const [classCode, setClassCode] = useState<string | null>('');
localStorage.setItem('token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHRlcm5hbElkIjoiMTQ3MDYiLCJuYW1lIjoiUHJvX1VzZXIiLCJtZW1iZXJzaGlwIjoicHJvIiwiZGVmYXVsdENsYXNzQ29kZSI6IkkzOTFTNiIsImV4cCI6MTY4NTUzODEzMH0.D_wKhvleNApBgZAJicM1AHCuWqGqhgIWW3U3fAP6GGQ')
  const onTokenVerificationFailed = () => {
    setIsTokenVerified(false);
    setVerifying(false);
    removeToken();
    window.location.href = `${WP_URL}/wp-login.php?action=logout`;
  };

  const endSession = useCallback(() => {
    const currentClassCode = classCode ? classCode : getClassCode();

    socket?.emit(EventsMessagesEnum.EndSession, {
      classCode: currentClassCode,
    });
  }, [socket, classCode]);

  const onGetTeachersData = useCallback(async () => {
    if ((!!user && Object.keys(user)?.length >= 3) || !token || isTokenVerified) {
      if (!token && !isLoginOut) {
        window.location.href = WP_URL;
      }
      return;
    }

    try {
      setVerifying(true);
      const data = await getTeacherPayloadByToken(`${token}`);
      if (!data) {
        onTokenVerificationFailed();
        return;
      }
      setIsTokenVerified(true);
      setVerifying(false);
      updateUserContext(data);
      saveToken(token);
      replace('/');
    } catch (e) {
      onTokenVerificationFailed();
    }
  }, [user, token, isTokenVerified, updateUserContext, replace, isLoginOut]);

  useEffect(() => {
    void onGetTeachersData();
  }, [onGetTeachersData]);

  const handleSessionEnd = useCallback(() => {
    endSession();
    clearSessionData();
    setClassCode('');
    setRoomCreated(false);
  }, [endSession]);

  const handleLogout = useCallback(() => {
    setIsLoginOut(true);
    removeToken();
    handleSessionEnd();
    setIsTokenVerified(false);
    updateUserContext(null);
    window.location.href = `${WP_URL}/wp-login.php?action=logout`;
  }, [handleSessionEnd, updateUserContext]);

  useEffect(() => {
    const createRoomListener = (data: RoomListener) => {
      if (data.classCode !== getClassCode()) {
        return;
      }

      setClassCode(data.classCode);
      saveClassCode(data.classCode);
      saveSessionId(data.sessionId);
      setRoomCreated(true);
    };

    socket?.on(EventsMessagesEnum.RoomCreated, createRoomListener);
    return () => {
      socket?.off(EventsMessagesEnum.RoomCreated, createRoomListener);
    };
  }, [isTokenVerified, socket, socket?.connected, updateUserContext]);

  useEffect(() => {
    if (!socket || !socket.connected || !isTokenVerified) {
      return;
    }

    const invalidTokenListener = () => handleLogout();

    socket?.on(EventsMessagesEnum.InvalidToken, invalidTokenListener);
    return () => {
      socket?.off(EventsMessagesEnum.InvalidToken, invalidTokenListener);
    };
  }, [handleLogout, isTokenVerified, socket, socket?.connected]);

  return (
    <div>
      <div className="container">
        <NavBar active={2} />
        <div className="wrap">
          {verifying || !isTokenVerified ? (
            <p className="loader">{!isTokenVerified ? '' : 'loading...'}</p>
          ) : roomCreated ? (
            <TeachersClassroom onSessionEnd={handleSessionEnd} />
          ) : (
            <StartSession />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Classroom;
