import React, { useCallback, useEffect, useState } from 'react';

import useSocket from 'hooks/use-socket';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { AssistanceRequest } from 'types/assistance-request';
import { Alerts } from 'shared';
import { getStudentId, removeClassCode, removeStudentId, removeStudentName } from 'utils/local-storage';
import { getResolvedAssistanceRequests } from 'utils/get-resolved-assistance-requests';
import { QUEUE_LIMIT } from 'constants/room';

import './styles.scss';
import { SessionEnded } from '../../../../types/session-ended';

type Props = {
  onLeaveSession: () => void;
  classCode: string;
  initialAssistanceRequestsCount: number;
};

const teacherInRoom = true;

const StudentClassRoom: React.FC<Props> = ({ onLeaveSession, classCode, initialAssistanceRequestsCount }) => {
  const [comment, setComment] = useState('');
  const [assistanceRequested, setAssistanceRequested] = useState(false);
  const [assistanceRequestedLoading, setAssistanceRequestedLoading] = useState(false);
  const [assistanceRequestNumber, setAssistanceRequestNumber] = useState<number | null>(null);
  const [showUpsell, setShowUpsell] = useState(false);
  const [assignedRequestsCount, setAssignedRequestsCount] = useState(initialAssistanceRequestsCount);
  const [preventCancel, setPreventCancel] = useState(false);
  const [queueIsFull, setQueueIsFull] = useState<boolean>(false);
  const [positionInTheQueue, setPositionInTheQueue] = useState<number>(0);
  const socket = useSocket();

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (assistanceRequested) {
      setPreventCancel(true);
      interval = setInterval(() => {
        setPreventCancel(false);
      }, 1000);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [assistanceRequested, setPreventCancel]);

  const onRequestAssistance = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault();
      setAssistanceRequestedLoading(true);

      if (!socket || !socket.connected) {
        return;
      }

      socket.emit(EventsMessagesEnum.RequestAssistance, {
        comment,
        classCode,
      });

      setComment('');
    }, [socket, comment, classCode]
  );

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const queueIsNotFullListener = () => {
      setQueueIsFull(false);
    };

    socket.on(EventsMessagesEnum.QueueIsNotFull, queueIsNotFullListener);

    return () => {
      socket.off(EventsMessagesEnum.QueueIsNotFull, queueIsNotFullListener);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const queueIsFullListener = () => {
      setQueueIsFull(true);
    };

    socket.on(EventsMessagesEnum.QueueIsFull, queueIsFullListener);

    return () => {
      socket.off(EventsMessagesEnum.QueueIsFull, queueIsFullListener);
    };
  }, [socket, queueIsFull]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const assistanceRequestNumberListener = (assistanceRequestNumber: number) => {
      /* If the student gets an assistance request number then it means tht his assistance request was created. */
      setAssistanceRequested(true);
      setAssistanceRequestNumber(assistanceRequestNumber);
    };

    socket.on(EventsMessagesEnum.AssistanceRequestNumber, assistanceRequestNumberListener);
    return () => {
      socket.off(EventsMessagesEnum.AssistanceRequestNumber, assistanceRequestNumberListener);
    };
  }, [socket, assistanceRequested]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const joinRoomListener = (data: { countNotResolvedAssistanceRequests: number }) => {
      setAssignedRequestsCount(data?.countNotResolvedAssistanceRequests);
    };

    socket.on(EventsMessagesEnum.JoinedRoom, joinRoomListener);
    return () => {
      socket.off(EventsMessagesEnum.JoinedRoom, joinRoomListener);
    };
  }, [socket]);

  useEffect(() => {
    const sessionEndedListener = (data: SessionEnded) => {
      if (!classCode || classCode !== data?.classCode) {
        return;
      }

      setComment('');
      setAssistanceRequested(false);
      setAssistanceRequestNumber(null);
      removeClassCode();
      removeStudentId();
      removeStudentName();
      onLeaveSession();
    };

    socket?.on(EventsMessagesEnum.SessionEnded, sessionEndedListener);
    return () => {
      socket?.off(EventsMessagesEnum.SessionEnded, sessionEndedListener);
    };
  }, [onLeaveSession, socket, classCode]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const assistanceRequestsListener = (payload: { assistanceRequests: AssistanceRequest[] }) => {
      const resolvedRequests = getResolvedAssistanceRequests(payload?.assistanceRequests);
      if (resolvedRequests.length === 0) {
        setAssistanceRequestNumber(null);
        setAssistanceRequested(false);
        setAssistanceRequestedLoading(false);
        setAssignedRequestsCount(0);
      } else {
        const studentsAssistanceRequest = resolvedRequests?.find(a => a.studentId === getStudentId());

        if (!studentsAssistanceRequest) {
          setAssistanceRequested(false);
          setAssistanceRequestedLoading(false); // added this line to fix disconnection issue on date : 31st may 2022
        } else {
          setAssistanceRequested(true); /* if the user requested assistance and then reload the page */
          setAssistanceRequestNumber(studentsAssistanceRequest?.assistanceRequestNumber);
        }

        resolvedRequests?.map((resolvedRequest, index) => {
          if (resolvedRequest.studentId === getStudentId()) {
            setPositionInTheQueue(index + 1);
          }
        });
        setAssignedRequestsCount(resolvedRequests?.length);
      }
    };

    socket.on(EventsMessagesEnum.AssistanceRequests, assistanceRequestsListener);
    return () => {
      socket.off(EventsMessagesEnum.AssistanceRequests, assistanceRequestsListener);
    };
  }, [assistanceRequested, socket]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const disconnectListener = () => {
      setAssistanceRequestedLoading(true);
    };

    socket.on(EventsMessagesEnum.Disconnect, disconnectListener);
    return () => {
      socket.off(EventsMessagesEnum.Disconnect, disconnectListener);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket || !socket.connected) {
      return;
    }

    const connectListener = () => {
      setAssistanceRequestedLoading(false);
    };

    socket.on(EventsMessagesEnum.Connect, connectListener);
    return () => {
      socket.off(EventsMessagesEnum.Connect, connectListener);
    };
  }, [socket]);

  const onCancelAssistance = () => {
    if (!socket || !socket.connected || !assistanceRequested || preventCancel) return;

    socket.emit(EventsMessagesEnum.CancelAssistanceRequest, {
      comment,
      classCode,
    });
    setAssistanceRequested(false);
    setAssistanceRequestedLoading(false);
  };

  return (
    <div className="studentWrap">
      <div id="teacher-assistance-student" className="text-center">
        {!assistanceRequested ? (
          <>
            {teacherInRoom ? (
              <form onSubmit={onRequestAssistance}>
                <button type="submit" className="assist-me" disabled={assistanceRequestedLoading}>
                  Assistance Needed
                </button>
                <div className="content">
                  <input
                    id="student-comment"
                    className="input ask-help"
                    type="text"
                    placeholder="Comment"
                    value={comment}
                    onChange={event => setComment(event.target.value)}
                  />
                  {queueIsFull ? (
                    <p className="queue-info">
                      Sorry, the queue is currently full. {'\n'}
                      Please wait until there are less than {QUEUE_LIMIT} people in the queue to add your name.
                    </p>
                  ) : (
                    <p className="queue-info">
                      Your teacher is currently assisting <strong>{assignedRequestsCount}</strong>{' '}
                      {assignedRequestsCount === 1 ? 'person' : 'people'} right now.
                    </p>
                  )}
                </div>
              </form>
            ) : (
              <p>The teacher is currently not in the room.</p>
            )}
            <Alerts.CQUpsell showUpsell={showUpsell} setShowUpsell={setShowUpsell} />
            <Alerts.Kicked />
          </>
        ) : (
          <>
            <button className="im-good" onClick={onCancelAssistance}>
              Cancel
            </button>
            <div>
              {assistanceRequestNumber && (
                <p>
                  Assistance is on the way. You’re number <b>{positionInTheQueue}</b> in line!
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StudentClassRoom;
