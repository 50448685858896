const DEFAULT_CONFIG = {
  apiUrl: 'http://localhost:8000',
  wpUrl: 'http://localhost:9001',
};

export const API_URL = process.env.REACT_APP_API_URL || DEFAULT_CONFIG.apiUrl;

export const DOCUMENT_TITLE = 'ClassroomQ';

export const WP_URL = process.env.REACT_APP_WP_URL || DEFAULT_CONFIG.wpUrl;
