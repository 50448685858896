import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Buttons } from 'shared/index';
import { WP_URL } from 'constants/config';

import './styles.scss';

type Props = {
  show?: boolean;
  onConfirm: () => void;
};

export const EndFreeSessionAlert: React.FC<Props> = ({ show = false, onConfirm }) => (
  <SweetAlert
    show={show}
    title="Session Log"
    info
    onConfirm={onConfirm}
    customButtons={
      <>
        <Buttons.Common size="md" name="OK" stylesBtn="yesButton" stylesWrap="buttonWrap" onClick={onConfirm} />
      </>
    }
  >
    <p>Session logs are available in ClassroomQ Pro.</p>
    <p>
      <a
        target="_blank"
        rel="noreferrer"
        href={`${WP_URL}/membership-account/membership-checkout/?level=2`}
        className="pro-membership-link"
      >
        Click here
      </a>{' '}
      to become a pro member.
    </p>
  </SweetAlert>
);
