import React from 'react';

import { ReactComponent as WarningImg } from '../../assets/icons/warning.svg';
import './styles.scss';

type Props = {
  message: string;
};

const Warning: React.FC<Props> = ({ message }) => {
  return (
    <div className="warning-container">
      <div className="warning-img-container">
        <WarningImg />
      </div>
      <div>{message}</div>
    </div>
  );
};

export default Warning;
