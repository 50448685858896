import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from '../buttons/Button';

type Props = {
  nameToKick: string;
  onConfirm: () => void;
  onCancel: () => void;
  show?: boolean;
};

export const KickAlert: React.FC<Props> = ({ nameToKick, show = false, onConfirm, onCancel }) => (
  <SweetAlert
    show={show}
    title="Remove student"
    confirmBtnText="Yes"
    confirmBtnBsStyle="warning"
    danger
    showCloseButton
    showCancel
    cancelBtnText="No"
    cancelBtnBsStyle="light"
    onConfirm={onConfirm}
    onCancel={onCancel}
    customButtons={
      <Fragment>
        <Button name="Ok" stylesBtn="yesButton" stylesWrap="buttonWrap" onClick={onConfirm} />
        <Button name="No" stylesBtn="backButton" stylesWrap="wrapBtnsGroup" onClick={onCancel} />
      </Fragment>
    }
  >
    <div>
      <p>
        Are you sure you want to remove the user with the name <strong>{nameToKick}</strong>?
      </p>
    </div>
  </SweetAlert>
);
