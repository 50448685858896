import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Button from '../buttons/Button';

type Props = {
  showUpsell: boolean;
  setShowUpsell: (show: boolean) => void;
};

export const CQUpsellAlert: React.FC<Props> = ({ showUpsell, setShowUpsell }) => (
  <SweetAlert
    show={showUpsell}
    title="Queue Limit Reached"
    confirmBtnText="Ok"
    confirmBtnBsStyle="warning"
    warning
    showCloseButton
    onConfirm={() => {
      setShowUpsell(false);
    }}
    onCancel={() => {
      setShowUpsell(false);
    }}
    customButtons={
      <Button
        name="Ok"
        stylesBtn="welcomeButton"
        stylesWrap="buttonWrap"
        onClick={() => {
          setShowUpsell(false);
        }}
      />
    }
  >
    <div>
      <p>The queue is currently limited to 5 assistance requests.</p>
      <p>Try again when you see your teacher assistance queue falls under 5.</p>
    </div>
  </SweetAlert>
);
