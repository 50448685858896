import { useContext } from 'react';
import { userContext } from 'context/user-context';
import { MembershipEnum } from 'enums/membership.enum';

export const membershipTypePaid = (): boolean => {
  const { state } = useContext(userContext);

  return state?.membership === MembershipEnum.Pro;
};

export const membershipTypeBasic = (): boolean => {
  const { state } = useContext(userContext);

  return state?.membership !== MembershipEnum.Pro;
};
