import React, { useContext, useMemo } from 'react';

import { Buttons } from 'shared';
import { getClassCode, getToken } from 'utils/local-storage';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import useSocket from 'hooks/use-socket';
import { AssistanceRequest } from 'types/assistance-request';
import { userContext } from 'context/user-context';

type Props = {
  onPlaySoundsChange: () => void;
  onShowCommentsChange: () => void;
  onEndSession: () => void;
  unResolvedRequests: AssistanceRequest[];
};

export const Toolbar: React.FC<Props> = ({
  onPlaySoundsChange,
  onShowCommentsChange,
  onEndSession,
  unResolvedRequests,
}) => {
  const socket = useSocket();
  const { state: user } = useContext(userContext);
  const classCode = useMemo(() => getClassCode(), []);
  const sharedLink = useMemo(() => `${window?.location?.origin}/students?classCode=${classCode}`, [classCode]);

  const clearQueue = () => {
    if (!socket || !socket.connected || !user || Object.keys(user)?.length < 3) return;

    socket?.emit(EventsMessagesEnum.ClearAllAssistanceRequests, {
      teacherId: user.id,
      classCode,
      token: getToken(),
    });
  };

  return (
    <div className="teacher-toolbar">
      <div className="title">
        Class Code: <span className="class-code">{classCode}</span>
      </div>
      <div className="buttons-container">
        <Buttons.Common
          name="Clear Queue"
          stylesBtn="button"
          stylesWrap="buttonWrap"
          onClick={clearQueue}
          disabled={unResolvedRequests?.length === 0}
        />
        <Buttons.Common name="End Session" stylesBtn="button" stylesWrap="buttonWrap" onClick={onEndSession} />
      </div>
      <Buttons.CopyToClipboard textToCopy={sharedLink} />
      <div className="toolbar-actions">
        <div className="play-sounds-container">
          <label className="switch">
            <input type="checkbox" id="sounds" onClick={onPlaySoundsChange} />
            <div className="slider" />
          </label>
          <p>Play Sounds</p>
        </div>
        <div className="show-comments-container">
          <label className="switch">
            <input type="checkbox" id="comments" onClick={onShowCommentsChange} />
            <div className="slider" />
          </label>
          <p>Show Comments</p>
        </div>
      </div>
    </div>
  );
};
