const STORAGE_KEYS = {
  classCode: 'classCode',
  sessionId: 'sessionId',
  token: 'token',
  studentId: 'studentId',
  studentName: 'studentName',
};

export const saveClassCode = (classCode: string) => localStorage.setItem(STORAGE_KEYS.classCode, classCode);
export const getClassCode = () => localStorage.getItem(STORAGE_KEYS.classCode);
export const removeClassCode = () => localStorage.removeItem(STORAGE_KEYS.classCode);

export const saveSessionId = (sessionId: string) => localStorage.setItem(STORAGE_KEYS.sessionId, sessionId);
export const getSessionId = () => localStorage.getItem(STORAGE_KEYS.sessionId);
export const removeSessionId = () => localStorage.removeItem(STORAGE_KEYS.sessionId);

export const saveToken = (token: string) => localStorage.setItem(STORAGE_KEYS.token, token);
export const getToken = () => localStorage.getItem(STORAGE_KEYS.token);
export const removeToken = () => localStorage.removeItem(STORAGE_KEYS.token);

export const saveStudentId = (studentId: string) => localStorage.setItem(STORAGE_KEYS.studentId, studentId);
export const getStudentId = () => localStorage.getItem(STORAGE_KEYS.studentId);
export const removeStudentId = () => localStorage.removeItem(STORAGE_KEYS.studentId);

export const saveStudentName = (studentName: string) => localStorage.setItem(STORAGE_KEYS.studentName, studentName);
export const getStudentName = () => localStorage.getItem(STORAGE_KEYS.studentName);
export const removeStudentName = () => localStorage.removeItem(STORAGE_KEYS.studentName);

export const clearSessionData = () => {
  removeClassCode();
  removeSessionId();
  removeStudentId();
  removeStudentName();
};
