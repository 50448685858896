import React, { useContext, useEffect, useMemo, useState } from 'react';

import useSocket from 'hooks/use-socket';
import { Buttons, Checkbox } from 'shared';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { userContext } from 'context/user-context';
import { getToken } from 'utils/local-storage';

export const StartSession: React.FC = () => {
  const socket = useSocket();
  const { state: user } = useContext(userContext);
  const isUser = useMemo(() => !!user && Object.keys(user)?.length > 3, [user]);

  const [useDefaultCode, setUseDefaultCode] = useState(true);

  const handleUseDefaultCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => setUseDefaultCode(e.target.checked);

  const startSession = () => {
    if (!socket || !socket.connected || !isUser) return;

    socket?.emit(EventsMessagesEnum.CreateTeachersRoom, {
      teacherId: user?.id,
      useDefaultClassCode: useDefaultCode,
      token: getToken(),
    });
  };

  useEffect(() => {
    if (!socket || !socket.connected || !isUser) return;

    const roomWasNotCreatedListener = () => {
      alert('Room was not created. Please try again.');
    };

    socket.on(EventsMessagesEnum.RoomWasNotCreated, roomWasNotCreatedListener);

    return () => {
      socket.off(EventsMessagesEnum.RoomWasNotCreated, roomWasNotCreatedListener);
    };
  }, [isUser, socket]);

  return (
    <div className="start-session-wrapper">
      <div className="start-session-btn-container">
        <h3 className="start-session-title">
          Click to start a new <span>ClassroomQ</span> session
        </h3>
        <Buttons.Common name="Start" stylesBtn="button" onClick={startSession} />
        <Checkbox
          id="default_class_code_checkbox"
          checked={useDefaultCode}
          onChange={handleUseDefaultCodeChange}
          labelText="Use default class code"
        />
      </div>
    </div>
  );
};
