import { AssistanceRequest } from 'types/assistance-request';
import { RoomParticipant } from 'types/room-participant';

export const addRequestsCountToParticipants = (participants: RoomParticipant[], requests: AssistanceRequest[]) => {
  if (!participants?.length) return [];

  return participants?.map(participant => ({
    requestsCount: requests?.filter(r => r?.studentId === participant?.studentId)?.length || 0,
    ...participant,
  }));
};