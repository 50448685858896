import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from 'shared/buttons/Button';

type Props = {
  show?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const QueueLimitReachedAlert: React.FC<Props> = ({ show = false, onConfirm, onCancel }) => (
  <SweetAlert
    show={show}
    title="Queue Limit Reached"
    confirmBtnText="Ok"
    confirmBtnBsStyle="warning"
    warning
    cancelBtnText="No"
    onConfirm={onConfirm}
    onCancel={onCancel}
    customButtons={
      <Fragment>
        <Button name="Ok" stylesBtn="yesButton" stylesWrap="buttonWrap" onClick={onConfirm} />
        <Button name="No" stylesBtn="backButton" stylesWrap="wrapBtnsGroup" onClick={onCancel} />
      </Fragment>
    }
  >
    <div>
      <p>The queue is currently full but your students are trying to add more questions.</p>
      <p>
        Pro members have unlimited names that can be added to the queue!{' '}
        <a href="<?php echo site_url(); ?>/membership-account/membership-checkout/?level=2">Click here</a> to become a
        pro member.
      </p>
    </div>
  </SweetAlert>
);
